import { css, styled } from "styled-components";

import { placeholderAnimation, placeholderBackground } from "@Style/animations/placeholder";
import { DefaultTheme } from "styled-components";

const LOGO_WIDTH = 80;
const LOGO_HEIGHT = 80;

export const Experience = styled('div')(
  () => css`
    background-color: #fff;
    min-height: calc(100% - 200px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    
    :first-child {
        margin-block-start: 0;
    }
    :last-child {
        margin-block-end: 0;
    }
  `
);

export const Heading = styled('h1').withConfig({
  shouldForwardProp: (prop) => ![
      'theme'
  ].includes(prop)
})<{
  theme: DefaultTheme
}>(
  ({ theme }) => css`
    margin-block-start: 0;
    margin-block-end: 1rem;
    padding-block-end: 1rem;

    &::after {
        background: ${theme.colors.SEPARATOR_COLOR};
        display: block;
        content: '';
        width: 100%;
        height: 1px;
        position: relative;
        bottom: -1rem;
    }
  `
);

export const List = styled('ul').withConfig({
  shouldForwardProp: (prop) => ![
      'isPlaceholder'
  ].includes(prop)
})<{
  isPlaceholder?: boolean
}>(
  ({
    isPlaceholder
  }) => css`
    margin: 0;
    padding: 0;
    list-style-type: none;
    position: relative;
    display: grid;
    grid-gap: 2rem;

    ${isPlaceholder && placeholderAnimation}
  `
);

export const Item = styled('li').withConfig({
  shouldForwardProp: (prop) => ![
      'theme'
  ].includes(prop)
})<{
  theme: DefaultTheme
}>(
  ({ theme }) => css`
    &:not(:last-child):after {
        background: ${theme.colors.SEPARATOR_COLOR};
        display: block;
        content: '';
        width: 100%;
        height: 1px;
        position: relative;
        bottom: -1rem;
    }
  `
);

export const Intro = styled('div')(
  () => css`
    display: flex;
    grid-gap: 1rem;
    align-items: center;
    padding-block-end: 1rem;
  `
);

export const Logo = styled('div').withConfig({
  shouldForwardProp: (prop) => ![
      'theme',
      'isPlaceholder'
  ].includes(prop)
})<{
  theme: DefaultTheme,
  isPlaceholder?: boolean
}>(
  ({
    theme,
    isPlaceholder
  }) => css`
    flex-shrink: 0;
    width: ${LOGO_WIDTH}px;
    height: ${LOGO_HEIGHT}px;
    background-color: ${theme.colors.SEPARATOR_COLOR};

    img {
        width: 100%;
        vertical-align: middle;
        position: relative;
        z-index: 1;
    }

    ${isPlaceholder && placeholderBackground}
  `
);

export const Details = styled('div')(
  () => css`

  `
);

export const Company = styled('div').withConfig({
  shouldForwardProp: (prop) => ![
      'isPlaceholder'
  ].includes(prop)
})<{
  isPlaceholder?: boolean
}>(
({
  isPlaceholder
}) => css`
    font-size: 1.2rem;
    font-weight: bold;

    ${isPlaceholder && `
      ${placeholderBackground}
      min-width: 150px;
      width: fit-content;
    `}
  `
);

export const Since = styled('div').withConfig({
  shouldForwardProp: (prop) => ![
      'isPlaceholder'
  ].includes(prop)
})<{
  isPlaceholder?: boolean
}>(
  ({
    isPlaceholder
  }) => css`
    ${isPlaceholder && `
      ${placeholderBackground}
      min-width: 120px;
      width: fit-content;
    `}
  `
);

export const Positions = styled('ul')(
  () => css`
    margin: 0;
    padding: 0;
    list-style-type: none;

    display: grid;
    grid-gap: 1rem;
    margin-left: ${LOGO_WIDTH}px;
    padding-inline-start: 1rem;
  `
);

export const Position = styled('li').withConfig({
  shouldForwardProp: (prop) => ![
      'theme'
  ].includes(prop)
})<{
  theme: DefaultTheme
}>(
  ({ theme }) => css`
    position: relative;
  
    &::before {
        display: block;
        content: '';
        height: calc(100% + 1rem);
        width: 1px;
        background: ${theme.colors.SEPARATOR_COLOR};
        position: absolute;
        bottom: 0;
        right: calc(100% + 1rem + (${LOGO_WIDTH}px / 2));
    }
    &::after {
        display: block;
        content: '';
        height: 1px;
        width: calc(${LOGO_WIDTH}px / 2);
        background: ${theme.colors.SEPARATOR_COLOR};
        position: absolute;
        bottom: 50%;
        right: calc(100% + 1rem);
    }
    &:last-child::before {
        height: calc(50% + 1rem);
        bottom: 50%;
    }
  `
);

export const Role = styled('div').withConfig({
  shouldForwardProp: (prop) => ![
      'isPlaceholder'
  ].includes(prop)
})<{
  isPlaceholder?: boolean
}>(
  ({
    isPlaceholder
  }) => css`
    font-weight: bold;

    ${isPlaceholder && `
      ${placeholderBackground}
      min-width: 75%;
      width: fit-content;
    `}
  `
);

export const Time = styled('div').withConfig({
  shouldForwardProp: (prop) => ![
      'isPlaceholder'
  ].includes(prop)
})<{
  isPlaceholder?: boolean
}>(
  ({
    isPlaceholder
  }) => css`
    opacity: 0.6;

    ${isPlaceholder && `
      ${placeholderBackground}
      min-width: 100%;
      width: fit-content;
    `}
  `
);

export const Location = styled('div').withConfig({
  shouldForwardProp: (prop) => ![
      'isPlaceholder'
  ].includes(prop)
})<{
  isPlaceholder?: boolean
}>(
  ({
    isPlaceholder
  }) => css`
    ${isPlaceholder && `
      ${placeholderBackground}
      min-width: 85%;
      width: fit-content;
    `}
  `
);
