/* eslint-disable react/prop-types */
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import IconChevron from '@Components/IconChevron';
import IconCheck from '@Components/IconCheck';

import useOnClickOutside from '@Hooks/useOnClickOutside';

import { callbackOnKeys } from '@Util/Keyboard';

import * as S from './InputSelect.theme';

import useArrowKeysTabindexNavigation from '@Hooks/useArrowKeysTabindexNavigation';
import { useTheme } from 'styled-components';

interface PropsType {
    children: ReactNode;
    disabled?: boolean;
    value?: string;
    onChange?: (value?: string) => void;
}

interface OptionType {
    children: string;
    value?: string;
    disabled?: boolean;
}

const InputSelect: React.FC<PropsType> = ({
    children: propsChildren,
    disabled: isDisabled = false,
    value: propsValue,
    onChange
}) => {
    const theme = useTheme();
    const wrapperRef = useRef<HTMLDivElement>(null);
    const nativeSelectRef = useRef<HTMLSelectElement>(null);
    const selectRef = useRef<HTMLDivElement>(null);
    const optionsRef = useRef<HTMLUListElement>(null);

    const [ active, setActive ] = useState(false);
    const [ selectedValue, setSelectedValue ] = useState<string | undefined>(undefined);

    // Remove focus if changed to disabled
    useEffect(() => {
        if (isDisabled && selectRef.current) {
            selectRef.current.blur();
        }
    }, [isDisabled]);

    const isActive = useMemo(() => {
        if (isDisabled) {
            return false;
        }

        return active;
    }, [active, isDisabled]);

    const options = useMemo(() => {
        if (propsChildren instanceof Array) {
            return propsChildren.map((child) => child.props);
        }

        return [];
    }, [propsChildren]);

    const actualValue = useMemo(() => {
        if (typeof propsValue !== 'undefined') {
            if (options.find((option) => option.value === propsValue || option.children === propsValue)) {
                return propsValue;
            }
        }

        if (typeof selectedValue !== 'undefined') {
            return selectedValue;
        }

        const [ { value: firstValue = undefined, children: firstChildren = undefined } = {} ] = options;
        return firstValue ?? firstChildren ?? undefined;
    }, [propsValue, selectedValue, options]);

    useOnClickOutside(wrapperRef, useCallback(() => setActive(false), []));
    useArrowKeysTabindexNavigation(optionsRef, wrapperRef);

    const selectOptionHandler = ({ value, children }: OptionType) => {
        if (isDisabled) {
            return;
        }

        if (options.find((option) => option.value === value || option.children === children).disabled) {
            return;
        }

        const newSelectedValue = value ?? children;

        closeSelectHandler();

        if (nativeSelectRef.current) {
            nativeSelectRef.current.value = newSelectedValue;
        }

        if (typeof onChange === 'function') {
            onChange(newSelectedValue);
        } else {
            setSelectedValue(newSelectedValue);
        }
    };

    const selectNativeOptionHandler = (newSelectedValue: string) => {
        if (isDisabled) {
            return;
        }

        if (options.find((option) => option.value === newSelectedValue || option.children === newSelectedValue).disabled) {
            return;
        }

        if (typeof onChange === 'function') {
            onChange(newSelectedValue);
        } else {
            setSelectedValue(newSelectedValue);
        }
    };

    const closeSelectHandler = () => {
        setActive(false);
        if (selectRef.current) {
            selectRef.current.focus();
        }
    };

    const openSelectHandler = () => {
        setActive(true);
    };

    const toggleSelectHandler = () => {
        if (isActive) {
            closeSelectHandler();
        } else {
            openSelectHandler();
        }
    };

    const onBlurHandler = () => {
        setTimeout(() => {
            if (isActive && !wrapperRef.current?.contains(document.activeElement)) {
                closeSelectHandler();
            }
        }, 10);
    };

    const renderNativeOptions = () => {
        return options.map(({ value, disabled, children }) => (
                <option
                    key={ value ?? children }
                    value={ value }
                    disabled={ disabled }
                >
                    { children }
                </option>
            )
        );
    };

    const renderOptions = () => {
        return options.map((option) => (
            <S.Option key={ option.value ?? option.children } theme={theme}
                isDisabled={ option.disabled }
                tabIndex={ option.disabled ? undefined : -1 }
                onClick={ () => selectOptionHandler(option) }
                onKeyDown={ (event) => {
                    callbackOnKeys(event, ['Enter', ' '], () => selectOptionHandler(option));
                    callbackOnKeys(event, ['Escape'], closeSelectHandler);
                } }
            >
                { (option.value === actualValue || option.children === actualValue) && <IconCheck /> }
                <span>{ option.children }</span>
            </S.Option>
        ));
    };

    const renderSelectedOptionLabel = () => {
        const { children: selectedLabel } = options.find((option) => option.value === actualValue || option.children === actualValue) || {};

        if (selectedLabel) {
            return <span>{ selectedLabel }</span>;
        }

        return <span></span>;
    }

    return (
        <S.InputSelect ref={ wrapperRef } onBlur={ onBlurHandler }>
            <select ref={ nativeSelectRef }
                disabled={ isDisabled }
                onChange={ (event: React.ChangeEvent<HTMLSelectElement>) => selectNativeOptionHandler(event.target.value) }
                value={ actualValue }
                tabIndex={ -1 }
            >
                { renderNativeOptions() }
            </select>
            <S.Select ref={ selectRef }
                theme={theme}
                isDisabled={isDisabled}
                tabIndex={ isDisabled ? -1 : 0 }
                onClick={ () => setActive(!isActive) }
                onKeyDown={ (event) => {
                    callbackOnKeys(event, ['Enter', ' '], toggleSelectHandler);
                    callbackOnKeys(event, ['ArrowDown', 'ArrowUp'], openSelectHandler);
                    callbackOnKeys(event, ['Escape'], closeSelectHandler);
                } }
            >
                <IconChevron />
                { renderSelectedOptionLabel() }
            </S.Select>
            <S.List ref={ optionsRef } isActive={isActive} theme={theme}>
                { renderOptions() }
            </S.List>
        </S.InputSelect>
    );
}

export default InputSelect;
