import { css, styled } from "styled-components";

export const ContentWrapper = styled('div')(
  () => css`
    position: relative;
    width: 100%;
    max-width: 1000px;
    padding: 1.5rem;
    margin-inline: auto;
  `
);
