import { css, styled } from "styled-components";

export const About = styled('div')(
  () => css`
    background-color: #fff;
    min-height: calc(100% - 200px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    
    :first-child {
        margin-block-start: 0;
    }
    :last-child {
        margin-block-end: 0;
    }
  `
);

export const Heading = styled('h1').withConfig({
  shouldForwardProp: (prop) => ![
      'theme'
  ].includes(prop)
})(
  ({ theme }) => css`
    margin-block-start: 0;
    margin-block-end: 1rem;
    padding-block-end: 1rem;

    &::after {
        background: ${theme.colors.SEPARATOR_COLOR};
        display: block;
        content: '';
        width: 100%;
        height: 1px;
        position: relative;
        bottom: -1rem;
    }
  `
);

export const Photo = styled('div')(
  () => css`
    float: left;
    margin-right: 1rem;
    margin-bottom: .5rem;
    width: 50%;
    max-width: 370px;
    height: auto;
    shape-outside: polygon(0% 0%, 71% 0, 77% 13%, 74% 29%, 88% 44%, 94% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 71% 0, 77% 13%, 74% 29%, 88% 44%, 94% 100%, 0% 100%);
  `
);
