/**
 * To be replaced by useTheme... to-do:
 * - Make into getter with theme parameter
 * - Replace accross app
 */
import { theme } from '@Style/theme';

/*
** Use placeholder background in the elements that should be placeholders.
*/
export const placeholderBackground = `
    position: relative;
    opacity: 1;
    min-height: 1lh;

    &::before {
        content: '\\200B';
    }

    &::after {
        z-index: 1;
        position: absolute;
        inset: 0;
        display: block;
        content: '';
        border: 1px solid #fff;
        background-color: ${theme.colors.PRIMARY_LIGHT};
    }
`;

/*
** Use placeholder animation in the wrapper element that has to be animated.
*/
export const placeholderAnimation = `
    position: relative;

    &::before {
        display: block;
        content: " ";
        position: absolute;
        inset: 0;
        z-index: 10;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, gray, transparent);
        background-repeat: no-repeat;
        background-size: 10rem 100%;
        mix-blend-mode: color-burn;
        animation: placeholder-animation 1.5s ease-in-out infinite;

        @keyframes placeholder-animation {
            0% {
                background-position-x: calc(0% - 10rem);
            }
            100% {
                background-position-x: calc(100% + 10rem);
            }
        }
    }
`;
