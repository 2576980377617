export const generateRandomHash = (length = 8): string => {
    let result = '';
    const allowedCharacters ='abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789_-';

    for (let i = 0; i < length; i++) {
        result += allowedCharacters.charAt(Math.floor(Math.random() * allowedCharacters.length));
    }

    return result;
}
