import { ReactNode } from 'react';
import * as S from './ButtonGroup.theme';

interface PropsType {
    children?: ReactNode;
}

const ButtonGroup: React.FC<PropsType> = ({
    children
}: PropsType) => (
    <S.ButtonGroup>{ children }</S.ButtonGroup>
);

export default ButtonGroup;
