import { css, styled } from "styled-components";

export const CustomLoadingModal = styled('div')(
  () => css`
    position: relative;
    height: 100%;
  `
);

export const Text = styled('p')(
  () => css`
    position: absolute;
    z-index: 100;
    width: 100%;
    top: 60%;
    text-align: center;
  `
);
