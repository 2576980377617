import { css, styled } from "styled-components";

export const ErrorModal = styled('div')(
  () => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.5rem;
    height: 100%;
  `
);

export const Title = styled('h1')(
    () => css`
      margin: 0;
    `
  );

  export const Message = styled('p')(
    () => css`
      margin: 0;
    `
  );

export const ButtonGroup = styled('div')(
    () => css`
      display: inline-flex;
      gap: 1rem;
      width: 100%;

      & > * {
        flex-grow: 1;
        flex-basis: 100%;
      }
    `
  );
