import IconTriangleExclamation from '@Components/IconTriangleExclamation';
import IconTimes from '@Components/IconTimes';

import { useEffect } from 'react';

import { useToast } from '@Hooks/useToast';

import * as ToastTypes from '@Types/Toast/Toast.types';
import * as S from './Toast.theme';

function Toast(props: ToastTypes.ToastNotification) {
    const {
        id,
        type,
        title,
        message,
        isDismissable,
        autoDismiss,
        autoDismissTimeout
    } = props;

    const { removeToast } = useToast();

    useEffect(() => {
        const timer = setTimeout(() => {
            if (autoDismiss) {
                removeToast(id);
            }
        }, autoDismissTimeout);
        return () => clearTimeout(timer);
    }, [removeToast]);

    const handleDismissClick = () => removeToast(id);

    return (
        <S.Toast type={ type }>
            <S.Icon>
                <IconTriangleExclamation />
            </S.Icon>
            <S.Content>
                <strong>{ title }</strong>
                <p>{ message }</p>
            </S.Content>
            { isDismissable && (
                <S.Dismiss onClick={ handleDismissClick }>
                    <IconTimes />
                </S.Dismiss>
            ) }
        </S.Toast>
    );
}

export default Toast;
