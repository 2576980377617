import { OpenModalAction, CloseModalAction } from "./actions";
import { Modal } from '@Types/Modal/Modal.types';

export type State = Modal[];
export type Action = OpenModalAction | CloseModalAction;

export function reducer(state: State = [], action: Action): State {
    switch (action.type) {
        case 'OPEN_MODAL':
            if (state.find((modal) => modal.id === action.payload.id)) {
                return state;
            }
            return [ ...state, action.payload ];

        case 'CLOSE_MODAL':
            return [ ...state.filter((modal) => modal.id !== action.payload.id) ];

        default:
            return state;
    }
}

// Reducer responsibility:
// - Reducing actions into updated state

// Test cases:
// - Initial state
// - Open a modal
// - Open a modal that is already open
// - Close a modal
