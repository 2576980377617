import { useState } from 'react';
import { useDocumentTitle } from '@Hooks/useDocumentTitle';

import ContentWrapper from '@Components/ContentWrapper';
import Field from '@Components/Field';
import InputSelect from '@Components/InputSelect';

import * as S from './Test.theme';

function Test() {
    useDocumentTitle('Test');

    const [inc, setInc] = useState(0);
    const [disabled, setDisabled] = useState(false);
    const [value, setValue] = useState('');

    return (
        <S.Test>
            <ContentWrapper>
                <h1>Test {inc}</h1>
                <button onClick={ () => setInc(inc+1) }>Re-render Test page (increment)</button><br/>
                <button onClick={ () => setDisabled(!disabled) }>Toggle disabled</button><br/>
                <button onClick={ () => setTimeout(() => setDisabled(true), 2000) }>Set disabled after 2 seconds</button><br/>
                <br/>
                <p>Value: {value}</p>
                <button onClick={ () => setValue('') }>Change to empty</button><br/>
                <button onClick={ () => setValue('red') }>Change to Red</button><br/>
                <button onClick={ () => setValue('green') }>Change to Green</button><br/>
                <button onClick={ () => setValue('blue') }>Change to Blue</button><br/>
                <button onClick={ () => setValue('something') }>Change to Something</button><br/>
                <Field>
                    <label>Select a colour</label>
                    <InputSelect
                        disabled={ disabled }
                        value={ value }
                        onChange={ (value) => setValue(value) }
                    >
                        <option disabled value="">Pick a colour</option>
                        <option value="red">Red</option>
                        <option value="green">Green</option>
                        <option value="blue">Blue</option>
                    </InputSelect>
                </Field>
                <p>Software Engineer with over 7 years of professional experience, including over 4 years of experience in front-end development, specialising in building high-quality applications with JavaScript/TypeScript.</p>
                <p>I&apos;m passionate about front-end development and anything related to modern web technologies, and I like coding, learning and sharing knowledge, problem-solving, collaborating, and making a positive impact.</p>
                <p>Regularly used: JavaScript, TypeScript, React.js, Jest, Node.js, NPM, Yarn, Webpack, Redux, GraphQL, ESLint, HTML, CSS (SCSS), PHP (Zend), Magento 2, MySQL, Docker, Python, TDD, DRY, SOLID, GitHub, Git, Agile/Scrum.</p>
            </ContentWrapper>
        </S.Test>
    );
}

export default Test;
