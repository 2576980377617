import { ReactNode } from 'react';

import * as S from './Field.theme';

interface PropsType {
    children?: ReactNode;
    onClick?: () => void;
}

const Field: React.FC<PropsType> = ({
    children,
    ...propsWithoutChildren
}: PropsType) => (
    <S.Field { ...propsWithoutChildren }>
        { children }
    </S.Field>
);

export default Field;
