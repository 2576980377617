import ContentWrapper from '@Components/ContentWrapper';
import Button from '@Components/Button';
import ButtonGroup from '@Components/ButtonGroup';

import * as S from './StyleGuideButton.theme';
import { useDocumentTitle } from '@Hooks/useDocumentTitle';
import { useTheme } from 'styled-components';

function StyleGuideButton() {
    const theme = useTheme();
    useDocumentTitle('Style Guide - Button');

    return (
        <S.StyleGuideButton>
            <ContentWrapper>
                <S.Heading theme={theme}>Button</S.Heading>
                <p>Below you can find all different types of buttons and options available.</p>
                <ButtonGroup>
                    <Button>Default</Button>
                    <Button disabled>Disabled</Button>
                    <Button isHollow>isHollow</Button>
                    <Button isLink>isLink</Button>
                </ButtonGroup>
                <br/>
                <ButtonGroup>
                    <Button onClick={(): void => alert('Works!')}>onClick</Button>
                    <Button onClick={(): void => alert('This doesn\'t work!')} disabled>onClick disabled</Button>
                </ButtonGroup>
                <br/>
                <Button isFullWidth>isFullWidth</Button>
                <br/><br/>
                <div style={{width: '100%', maxWidth: '600px'}}>
                    <p>The below button is wrapped in div with a max-width of 600px.</p>
                    <Button>Default button with text overflow text overflow text overflow text overflow text overflow text overflow text overflow text overflow</Button>
                </div>
                <br/>
                <div style={{width: '100%', maxWidth: '60%'}}>
                    <p>The below button is wrapped in div with a max-width of 60%.</p>
                    <Button>Default button with text overflow text overflow text overflow text overflow text overflow text overflow text overflow text overflow</Button>
                </div>
            </ContentWrapper>
        </S.StyleGuideButton>
    );
}

export default StyleGuideButton;
