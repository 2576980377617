import { createContext, PropsWithChildren } from 'react';

import { useReducer } from 'react';
import { reducer } from '@Reducers/Modals/reducer';
import { Modal } from '@Types/Modal/Modal.types';
import ModalLoader from '@Components/ModalLoader/ModalLoader.component';
import { openModal, closeModal } from '@Reducers/Modals/actions';

export interface ModalContext {
    openModal: <C extends Modal['config']>(modal: Modal<C>) => void,
    closeModal: (id: Modal['id']) => void
}

export const ModalContext = createContext<ModalContext>({} as ModalContext);

export default function ModalProvider({ children }: PropsWithChildren) {
    const [modals, dispatch] = useReducer(reducer, []);

    return (
        <ModalContext.Provider value={ {
            openModal: (modal) => dispatch(openModal(modal)),
            closeModal: (id) => dispatch(closeModal(id))
        } }>
            {children}
            { modals.map((modal) => {
                return (
                    <ModalLoader
                        key={modal.id}
                        { ...modal }
                        onClose={ () => {
                            dispatch(closeModal(modal.id));
                            modal.onClose?.();
                        } }
                    />
                );
            }) }
        </ModalContext.Provider>
    );
}
