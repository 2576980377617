import { createGlobalStyle, css } from "styled-components";

export const GlobalStyle = createGlobalStyle(
  ({
    theme
  }) => css`
    html {
        /* Keep identical to theme-color meta as this affects the bottom address bar color. */
        background-color: ${theme.colors.PRIMARY_BASE};
    }
    html, body, #root {
        height: 100%;
    }
    body {
        font-family: 'Be Vietnam Pro', sans-serif;
        font-size: ${theme.fontSize.BODY_MD}px;
        overflow-y: scroll;
        background-color: ${theme.colors.PRIMARY_BASE};
        padding-left: env(safe-area-inset-left);
        padding-right: env(safe-area-inset-right);
    }
    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }

    a {
        color: ${theme.colors.PRIMARY_BASE};
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }  
  `
);
