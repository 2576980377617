/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';

type Props = {
    lazyLoader: () => Promise<{ default: React.FC }>;
    render: (component: React.FC) => JSX.Element;
    renderFallback: () => JSX.Element;
    renderError: (errorProps: ErrorProps) => JSX.Element;
}

export type ErrorProps = {
  lazyLoader: {
      error: Error;
      retry: () => void;
  }
}

export type PropsWithLazyLoaderError<P = unknown> = P & ErrorProps;

type State = {
  isLoading: boolean;
  loadedComponent?: React.FC;
  error?: Error;
}

function LazyLoader(config: Props) {
    let promise;

    const initialState: State = {
        isLoading: true,
        loadedComponent: undefined,
        error: undefined
    };

    const [{
        isLoading,
        loadedComponent,
        error
    }, setState] = useState(initialState);

    const startLoading = () => {
        setState(initialState);

        promise = config.lazyLoader();

        promise.then((module) => {
            setState({
                isLoading: false,
                loadedComponent: module.default,
                error: undefined
            });
        }).catch((e) => {
            setState({
                isLoading: false,
                loadedComponent: undefined,
                error: e
            });
        });
    }

    useEffect(() => {
        startLoading();
    }, []);

    if (error) {
        return config.renderError({
            lazyLoader: {
                error: error,
                retry: startLoading
            }
        });
    }

    if (!loadedComponent || isLoading) {
        return config.renderFallback();
    }

    return config.render(loadedComponent);
}

export default LazyLoader;
