import useSWR from 'swr';

import ContentWrapper from '@Components/ContentWrapper';
import Image from '@Components/Image';

import { fetcher } from '@Util/Request';

import { ExperienceListType, ExperienceType, PositionType } from './Experience.config';

import { getFormattedDateDiff, getFormattedMonth } from '@Util/Date/Date.util';

import * as S from './Experience.theme';
import { useDocumentTitle } from '@Hooks/useDocumentTitle';
import { useTheme } from 'styled-components';

function Experience() {
    const theme = useTheme();
    useDocumentTitle('Experience');

    const { data, error, isLoading } = useSWR<ExperienceListType>('/experience.json', fetcher);
    
    const renderExperienceItem = (item: ExperienceType) => {
        const { id, company, logo_src, positions } = item;

        const getSinceValue = () => {
            // Get the first started and last ended position date
            const [ start = null ] = Array.from(positions)
                .map((positions) => positions.start)
                .sort((a, b) => {
                    if (a === null || b === null) {
                        return 0;
                    }

                    return new Date(a).getTime() - new Date(b).getTime();
                });

            if (!start) {
                return null;
            }

            const [ end = null ] = Array.from(positions)
                .map((positions) => positions.end)
                .sort((a, b) => {
                    if (a === null || b === null) {
                        return 0;
                    }

                    return new Date(b).getTime() - new Date(a).getTime();
                });

            // Null end is current position
            if (!end) {
                return getFormattedDateDiff(new Date(start), new Date());
            }

            return getFormattedDateDiff(new Date(start), new Date(end));
        };

        return (
            <S.Item theme={theme} key={id}>
                <S.Intro>
                    <S.Logo theme={theme} isPlaceholder={isLoading}>
                        {logo_src && <Image src={logo_src} height="200" width="200" alt={`${company} logo`} />}
                    </S.Logo>
                    <S.Details>
                        <S.Company isPlaceholder={isLoading}>{company}</S.Company>
                        <S.Since isPlaceholder={isLoading}>{getSinceValue()}</S.Since>
                    </S.Details>
                </S.Intro>
                <S.Positions>
                    {positions.map((position) => renderPosition(position))}
                </S.Positions>
            </S.Item>
        );
    }

    const renderPosition = (position: PositionType) => {
        const { id, role, location, start, end } = position;

        const getTimeValue = () => {
            if (!start) {
                return null;
            }

            const startDate = new Date(start);

            if (startDate.getTime() > new Date().getTime()) {
                return `Starting in ${getFormattedMonth(startDate)} ${startDate.getFullYear()}`
            }

            if (!end) {
                const endDate = new Date();

                const dates = `${getFormattedMonth(startDate)} ${startDate.getFullYear()} - Now`;
                const time = getFormattedDateDiff(startDate, endDate);

                return `${dates} (${time})`;
            }

            const endDate = new Date(end);

            const dates = `${getFormattedMonth(startDate)} ${startDate.getFullYear()} - ${getFormattedMonth(endDate)} ${endDate.getFullYear()}`;
            const time = getFormattedDateDiff(startDate, endDate);

            return `${dates} (${time})`;
        };

        return (
            <S.Position theme={theme} key={id}>
                <S.Role isPlaceholder={isLoading}>{role}</S.Role>
                <S.Time isPlaceholder={isLoading}>{getTimeValue()}</S.Time>
                <S.Location isPlaceholder={isLoading}>{location}</S.Location>
            </S.Position>
        );
    }

    const renderPlaceholder = () => {
        const placeholderData = [
            {
                id: 'placeholder1',
                company: null,
                logo_src: null,
                positions: [
                    {
                        id: 'position1',
                        role: null,
                        type: null,
                        location: null,
                        start: null,
                        end: null
                    },
                    {
                        id: 'position2',
                        role: null,
                        type: null,
                        location: null,
                        start: null,
                        end: null
                    }
                ]
            },
            {
                id: 'placeholder2',
                company: null,
                logo_src: null,
                positions: [
                    {
                        id: 'position1',
                        role: null,
                        type: null,
                        location: null,
                        start: null,
                        end: null
                    }
                ]
            }
        ];

        return placeholderData.map((item) => renderExperienceItem(item));
    };

    const renderList = () => {
        if (isLoading) {
            return renderPlaceholder();
        }

        if (!data || !data.length) {
            return <p>No experience to display.</p>;
        }

        return data.map((item) => renderExperienceItem(item));
    };

    const renderContent = () => {
        if (error) {
            return (
                <p>Error: { error.message }</p>
            );
        }

        return (
            <S.List isPlaceholder={ isLoading }>
                { renderList() }
            </S.List>
        );
    };

    return (
        <S.Experience>
            <ContentWrapper>
                <S.Heading theme={theme}>Experience</S.Heading>
                { renderContent() }
            </ContentWrapper>
        </S.Experience>
    );
}

export default Experience;
