import React, { PropsWithChildren, useState, useCallback } from 'react';

import * as AuthTypes from '@Types/Auth/Auth.types';
import useToast from '@Hooks/useToast';
import { getAuthFromLocalStorage, getRefreshToken } from '@Util/Auth';
import { postLogin } from 'app/API/postLogin';
import { postLogout } from 'app/API/postLogout';

export interface AuthContext {
    auth: AuthTypes.Auth | undefined,
    login: (email: string, password: string) => void,
    logout: () => void,
    isLoggedIn: boolean
}

export const AuthContext = React.createContext<AuthContext>({} as AuthContext);

export default function AuthProvider({ children }: PropsWithChildren) {
    const { addToast } = useToast();

    const [auth, setAuth] = useState<AuthContext['auth']>(getAuthFromLocalStorage());

    const login = useCallback<AuthContext['login']>(
        (email, password) => postLogin({ email, password })
            .then((authObject) => {
                localStorage.setItem('auth', JSON.stringify(authObject));
                setAuth(authObject);
                addToast('Login success', `Welcome back, ${authObject.user.name}!`, 'success');
            }).catch((error) => {
                addToast('Failed to login', error.message, 'error');
            }),
        [setAuth]
    );

    const logout = useCallback<AuthContext['logout']>(
        () => postLogout({ refreshToken: getRefreshToken() })
            .then(() => {
                localStorage.removeItem('auth');
                setAuth(undefined);
                addToast('Logout success', `You are no longer logged in.`, 'success');
            }).catch((error) => {
                console.log('### Logout error', error.message);
            }),
        [setAuth]
    );

    const isLoggedIn: AuthContext['isLoggedIn'] = Boolean(auth);

    return (
        <AuthContext.Provider value={ {
            auth,
            login,
            logout,
            isLoggedIn
        } }>
            {children}
        </AuthContext.Provider>
    );
}
