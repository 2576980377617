export const getAuthFromLocalStorage = () => {
    const auth = localStorage.getItem('auth');
    if (auth) {
        return JSON.parse(auth);
    }
    return undefined;
};

export const getAuthToken = () => {
    const auth = getAuthFromLocalStorage();
    const accessToken = auth?.tokens.access.token;
    if (accessToken) {
        return accessToken;
    }
    return undefined;
};

export const getRefreshToken = () => {
    const auth = getAuthFromLocalStorage();
    const refreshToken = auth?.tokens.refresh.token;
    if (refreshToken) {
        return refreshToken;
    }
    return undefined;
};
