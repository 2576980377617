import { Modal as ModalType } from '@Types/Modal/Modal.types';
import Modal from '@Components/Modal';
import Button from '@Components/Button';

import * as S from './ErrorModal.theme';
import { PropsWithLazyLoaderError } from '@Components/LazyLoader/LazyLoader.component';

type Props = PropsWithLazyLoaderError<{
    modal: ModalType;
}>;

const ErrorModal: React.FC<Props> = (props: Props) => {

    const {
        modal,
        modal: {
            onClose
        },
        lazyLoader: {
            error,
            retry
        }
    } = props;

    return (
        <Modal { ...modal }>
            <S.ErrorModal>
                <S.Title>Error loading</S.Title>
                <S.Message>Error: {error.toString()}</S.Message>
                <S.ButtonGroup>
                    <Button onClick={ onClose }>Close</Button>
                    <Button isHollow onClick={ retry }>Retry</Button>
                </S.ButtonGroup>
            </S.ErrorModal>
        </Modal>
    );
};

export default ErrorModal;
