import { FormEvent } from 'react';
import * as S from './InputText.theme';
import { useTheme } from 'styled-components';

type PropsType = {
    value?: string,
    placeholder?: string,
    readOnly?: boolean,
    disabled?: boolean,
    onChange?: (event: FormEvent<HTMLInputElement>) => void
};

const InputText: React.FC<PropsType> = (props: PropsType) => {
    const theme = useTheme();
	return <S.Input theme={theme} { ...props } />;
}

export default InputText;
