import { css, styled } from "styled-components";
import { DefaultTheme } from 'styled-components';

export const Button = styled('button').withConfig({
    shouldForwardProp: (prop) => ![
        'theme',
        'isHollow',
        'isLink',
        'isFullWidth'
    ].includes(prop)
})<{
    theme: DefaultTheme;
    isHollow?: boolean;
    isLink?: boolean;
    isFullWidth?: boolean;
}>(
  ({
    theme,
    isHollow,
    isLink,
    isFullWidth
  }) => css`
    padding: 1rem 1.5rem;
    font-size: 1.2rem;
    color: #fff;
    max-width: 100%;
    border: 2px solid ${theme.colors.PRIMARY_BASE};
    border-radius: 0;
    background: ${theme.colors.PRIMARY_BASE};
    white-space: nowrap;
    outline: none;
    user-select: none;
    font-weight: normal;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    overflow-x: hidden;

    &:active, &:focus {
        background-color: ${theme.colors.SECONDARY_BASE};
        border-color: ${theme.colors.SECONDARY_BASE};
    }

    ${isHollow && `
        color: ${theme.colors.PRIMARY_BASE};
        background: #fff;

        &:active, &:focus {
            background-color: #fff;
            color: ${theme.colors.SECONDARY_BASE};
            border-color: ${theme.colors.SECONDARY_BASE};
        }
    `}

    ${isLink && `
        color: ${theme.colors.PRIMARY_BASE};
        border-color: #fff;
        background: #fff;
        padding-inline: 0px;

        &:active, &:focus {
            background-color: #fff;
            color: ${theme.colors.SECONDARY_BASE};
            border-color: #fff;
        }
    `}

    ${isFullWidth && `
        width: 100%;
    `}

    &:disabled {
        opacity: 0.4;
        pointer-events: none;
    }
  `
);
