import { responseToJSON } from "@Util/Http";

type PostLoginParameters = {
    email: string;
    password: string;
};

type PostLoginResponseType = {
    user: {
        name: string;
        email: string;
        role: string;
        isEmailVerified: boolean;
        id: string;
    }
    tokens: {
        access: {
            token: string;
            expires: string;
        }
        refresh: {
            token: string;
            expires: string;
        }
    }
}

export const postLogin = (parameters: PostLoginParameters) => {
    console.log('### postLogin parameters', parameters);

    return fetch(`http://localhost:3001/v1/auth/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': `bearer ${getAuthToken()}`
            /// ...customHeaders
        },
        body: JSON.stringify(parameters)
    }).then(responseToJSON) as Promise<PostLoginResponseType>;
}
