import { useState } from 'react';

import { NavLink } from 'react-router-dom';
import ContentWrapper from '@Components/ContentWrapper';

import * as S from './Header.theme';
import { useTheme } from 'styled-components';

const Header: React.FC = () => {
    const theme = useTheme();
    const [active, setActive] = useState(false);

    const toggleMenu = () => setActive(!active);
    const closeMenu = () => setActive(false);

    return (
        <S.Header theme={theme}>
            <ContentWrapper>
                <S.Title>
                    <NavLink to="/" exact>Joost Meijer</NavLink>
                </S.Title>
                <S.Toggle theme={theme} isActive={active} onClick={ toggleMenu } />
                <S.List isActive={active}>
                    <li>
                        <S.Item theme={theme} to="/" onClick={ closeMenu } exact>About</S.Item>
                    </li>
                    <li>
                        <S.Item theme={theme} to="/experience" onClick={ closeMenu } exact>Experience</S.Item>
                    </li>
                    <li>
                        <S.Item theme={theme} to="/contact" onClick={ closeMenu } exact>Contact</S.Item>
                    </li>
                </S.List>
            </ContentWrapper>
        </S.Header>
    );
};

export default Header;
