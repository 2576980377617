/* eslint-disable react/prop-types */
import LoadingModal from '@Components/LoadingModal';
import ErrorModal from '@Components/ErrorModal';
import LazyLoader from '@Components/LazyLoader';
import { Modal } from '@Types/Modal/Modal.types';

export const ModalLoader = (modal: Modal) => {
    const FallbackComponent = modal.fallbackComponent || LoadingModal;
    const ErrorComponent = modal.errorComponent || ErrorModal;

    return (
        <LazyLoader
            lazyLoader={modal.component}
            render={(LoadedComponent: React.FC<Modal>) => <LoadedComponent { ...modal } />}
            renderFallback={() => <FallbackComponent { ...modal } />}
            renderError={(errorProps) => <ErrorComponent modal={modal} {...errorProps} />}
        />
    );
}

export default ModalLoader;
