import { css, styled } from "styled-components";

export const Field = styled('div')(
  () => css`
    display: grid;
    grid-gap: 0.2rem;

    label {
        user-select: none;
    }
  `
);
