import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { isDevelopmentEnvironment } from '@Util/Env';

const Contact = React.lazy(() => new Promise(resolve => { setTimeout(() => resolve(import('@Route/Contact'))); }));
const NotFound = React.lazy(() => new Promise(resolve => { setTimeout(() => resolve(import('@Route/NotFound'))); }));

import About from '@Route/About';
import Experience from '@Route/Experience';
import Test from '@Route/Test';
import Login from '@Route/Login';
import ToastPage from '@Route/ToastPage';
import StyleGuide from '@Route/StyleGuide';
import StyleGuideButton from '@Route/StyleGuideButton';
import StyleGuideImage from '@Route/StyleGuideImage';
import StyleGuideInputText from '@Route/StyleGuideInputText';
import StyleGuideTheme from '@Route/StyleGuideTheme';
import StyleGuideModal from '@Route/StyleGuideModal';

import Header from '@Components/Header';
import Footer from '@Components/Footer';
import FallbackLoader from '@Route/FallbackLoader';

function Router() {
    return (
        <BrowserRouter>
            <Header />
            <React.Suspense fallback={<FallbackLoader/>}>
                <Switch>
                    <Route path="/" exact component={About} />
                    <Route path="/experience" component={Experience} />
                    <Route path="/contact" component={Contact} />
                    {isDevelopmentEnvironment && <>
                        <Route path="/toast" component={ToastPage} />
                        <Route path="/test" component={Test} />
                        <Route path="/login" component={Login} />
                        <Route path="/logout" component={Login} />
                        <Route path="/admin" component={Login} />
                        <Route path="/admin/users" component={Login} />
                        <Route path="/admin/roles" component={Login} />
                        <Route path="/styleguide" exact component={StyleGuide} />
                        <Route path="/styleguide/button" component={StyleGuideButton} />
                        <Route path="/styleguide/input-text" component={StyleGuideInputText} />
                        <Route path="/styleguide/image" component={StyleGuideImage} />
                        <Route path="/styleguide/theme" component={StyleGuideTheme} />
                        <Route path="/styleguide/modal" component={StyleGuideModal} />
                    </>}
                    <Route path="/" component={NotFound} />
                </Switch>
            </React.Suspense>
            <Footer />
        </BrowserRouter>
    );
}

export default Router;
