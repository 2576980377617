import { Modal as ModalType } from '@Types/Modal/Modal.types';
import * as S from './Modal.theme';
import { PropsWithChildren, SyntheticEvent } from 'react';

function Modal(props: PropsWithChildren<ModalType>) {
    const {
        id,
        width,
        height,
        onClose,
        children
    } = props;

    const stopPropagation = (e: SyntheticEvent): void => e.stopPropagation();

    const handleClose = () => {
        onClose?.();
    };

    return (
        <S.Backdrop onClick={ handleClose } data-testid={`modal-${id}`}>
            <S.Modal onClick={stopPropagation} width={width} height={height}>
                { children }
            </S.Modal>
        </S.Backdrop>
    );
}

export default Modal;
