import ContentWrapper from '@Components/ContentWrapper';
import Image from '@Components/Image';

import * as S from './StyleGuideImage.theme';
import { useDocumentTitle } from '@Hooks/useDocumentTitle';
import { useTheme } from 'styled-components';

function StyleGuideImage() {
    const theme = useTheme();
    useDocumentTitle('Style Guide - Image');

    return (
        <S.StyleGuideImage>
            <ContentWrapper>
                <S.Heading theme={theme}>InputText</S.Heading>
                <p>Below you can find all different types of input text and options available.</p>
                <h3>Acceptance criteria</h3>
                <ul>
                    <li>Show loading state</li>
                    <li>Show error state</li>
                    <li>Show image state</li>
                    <li>Keep ratio at all times (given dimensions should match to be loaded image)</li>
                    <li>All sizing behaviour should be like a native img</li>
                    <li>Image should always size as big as possible in the available height/width while maintaining ratio</li>
                    <li>An actual height and or width in px or percent should be given by a wrapper div</li>
                </ul>
                <div style={{width: '100%', display: 'grid', gap: '1rem', gridTemplateColumns: '1fr 1fr 1fr' }}>
                    <Image src="https://placehold.it/200x200" width="200" height="200" alt="Alt here" />
                    <Image src="https://joostmeijer.net/doesntexist.png" width="200" height="200" alt="Alt here" />
                    <Image src="https://doesntexist.com/test.png" width="200" height="200" alt="Alt here" />
                </div>
                <br/>
                <div style={{width: '100%', display: 'grid', gap: '1rem', gridTemplateColumns: '1fr 1fr 1fr' }}>
                    <div style={{ display: 'inline-block', width: '100%', maxWidth: '200px' }}>
                        <Image src="https://placehold.it/100x200" width="100" height="200" alt="Alt here" />
                    </div>
                    <div style={{ display: 'inline-block', width: '100%', maxWidth: '200px' }}>
                        <Image src="https://joostmeijer.net/doesntexist.png" width="100" height="200" alt="Alt here" />
                    </div>
                    <div style={{ display: 'inline-block', width: '100%', maxWidth: '200px' }}>
                        <Image src="https://doesntexist.com/test.png" width="200" height="100" alt="Alt here" />
                    </div>
                </div>
                <br/>
                <div style={{ display: 'block', height: '100px' }}>
                    <img src="https://placehold.it/400x100" width="400" height="100" alt="Alt here" />
                </div>
                <div style={{ display: 'block', height: '100px' }}>
                    <Image src="https://placehold.it/400x100" width="400" height="100" alt="Alt here" />
                </div>
            </ContentWrapper>
        </S.StyleGuideImage>
    );
}

export default StyleGuideImage;
