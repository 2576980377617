import { PropsWithChildren } from 'react';
import * as S from './Button.theme';
import { useTheme } from 'styled-components';

type PropsType = PropsWithChildren<{
    disabled?: boolean,
    isHollow?: boolean,
    isLink?: boolean,
    isFullWidth?: boolean,
    onClick?: () => void
}>;

const Button: React.FC<PropsType> = ({
    children,
    ...propsWithoutChildren
}: PropsType) => {
    const theme = useTheme();

    return (
        <S.Button theme={theme} { ...propsWithoutChildren }>
            { children }
        </S.Button>
    );
};

export default Button;
