import { useEffect } from "react";

/**
 * This function calls the handler (callback) when a click occurs outside of the provided ref.
 * Performance note: Wrap the handler in useCallback to prevent unnecessary re-renders.
 * @param {*} ref
 * @param {*} handler
 */
export const useOnClickOutside = (
    ref: React.RefObject<HTMLElement>,
    handler: (event: MouseEvent | TouchEvent) => void
) => {
    useEffect(
        () => {
            const listener = (event: MouseEvent | TouchEvent) => {
                // Do nothing if clicking ref's element or descendent elements
                if (!ref.current || ref.current.contains(event.target as Element)) {
                    return;
                }
                handler(event);
            };
                document.addEventListener("mousedown", listener);
                document.addEventListener("touchstart", listener);
            return () => {
                document.removeEventListener("mousedown", listener);
                document.removeEventListener("touchstart", listener);
            };
        },
        [ref, handler]
    );
}

export default useOnClickOutside;
