import { css, styled } from "styled-components";

export const Backdrop = styled('div')(
  () => css`
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(0 0 0 / 20%);
  overflow: hidden;
  `
);

export const Modal = styled('div').withConfig({
  shouldForwardProp: (prop) => ![
      'width',
      'height'
  ].includes(prop)
})<{
    width: string,
    height: string
}>(
  ({
    width,
    height
  }) => css`
  width: 100%;
  max-width: ${width};
  height: 100%;
  max-height: ${height};
  background: white;
  `
);
