import { useState } from 'react';

import IconImageSlash from '@Components/IconImageSlash';

import * as S from './Image.theme';
import { useTheme } from 'styled-components';

type PropsType = {
    src: string,
    height: string,
    width: string,
    alt: string,
    onClick?: (event: React.MouseEvent<HTMLElement>) => void
};

const Image: React.FC<PropsType> = ({
    src,
    height,
    width,
    alt
}: PropsType) => {
    const theme = useTheme();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const onLoadHandler = () => {
        setLoading(false);
    };

    const onErrorHandler = () => {
        setError('Failed loading image');
        setLoading(false);
    };

    const renderError = () => {
        if (error) {
            return <S.Error>
                <IconImageSlash />
            </S.Error>;
        }

        return null;
    };

    return <S.Image theme={theme} isLoading={loading} isError={!!error}>
        <img
            onLoad={ onLoadHandler }
            onError={ onErrorHandler }
            src={src}
            height={height}
            width={width}
            alt={alt}
        />
        { renderError() }
    </S.Image>;
}

export default Image;
