import { css, styled } from "styled-components";

export const StyleGuide = styled('div')(
  () => css`
    background-color: #fff;
    min-height: calc(100% - 200px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    
    :first-child {
        margin-block-start: 0;
    }
    :last-child {
        margin-block-end: 0;
    }
  `
);

export const Heading = styled('h1').withConfig({
  shouldForwardProp: (prop) => ![
      'theme'
  ].includes(prop)
})(
  ({ theme }) => css`
    margin-block-start: 0;
    margin-block-end: 1rem;
    padding-block-end: 1rem;

    &::after {
        background: ${theme.colors.SEPARATOR_COLOR};
        display: block;
        content: '';
        width: 100%;
        height: 1px;
        position: relative;
        bottom: -1rem;
    }
  `
);
