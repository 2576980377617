import { Link } from 'react-router-dom';

import ContentWrapper from '@Components/ContentWrapper';

import * as S from './StyleGuide.theme';
import { useDocumentTitle } from '@Hooks/useDocumentTitle';
import { useTheme } from 'styled-components';

function StyleGuide() {
    const theme = useTheme();
    useDocumentTitle('Style Guide');

    return (
        <S.StyleGuide>
            <ContentWrapper>
                <S.Heading theme={theme}>Styleguide</S.Heading>
                <p>Below you can find all different components that are available in the design system.</p>
                <Link to="/styleguide/button">&#60;Button&#62;</Link><br/>
                <Link to="/styleguide/input-text">&#60;InputText&#62;</Link><br/>
                <Link to="/styleguide/image">&#60;Image&#62;</Link><br/>
                <Link to="/styleguide/theme">&#60;Theme&#62;</Link><br/>
                <Link to="/styleguide/modal">&#60;Modal&#62;</Link><br/>
            </ContentWrapper>
        </S.StyleGuide>
    );
}

export default StyleGuide;
