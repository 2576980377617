import { css, styled } from "styled-components";
import { DefaultTheme } from 'styled-components';

export const InputSelect = styled('div').withConfig({
  shouldForwardProp: (prop) => ![
      'useNativeSelect'
  ].includes(prop)
})<{
    useNativeSelect?: boolean;
}>(
  ({
    useNativeSelect
  }) => css`
    position: relative;

    select {
        position: absolute;
        inset: 0;
        z-index: 10;
        opacity: 0;
        border: 0px;
        outline: 0px;
        display: block;
        appearance: none;

        height: 0px;
        pointer-events: none;
    }

    ${useNativeSelect && `
        select {
            height: unset;
            pointer-events: unset;
        }
    `}
  `
);

export const Select = styled('div').withConfig({
  shouldForwardProp: (prop) => ![
      'theme',
      'isDisabled'
  ].includes(prop)
})<{
  theme: DefaultTheme;
  isDisabled?: boolean;
}>(
  ({
    isDisabled,
    theme
  }) => css`
    position: relative;
    padding: 1rem 1.5rem;
    padding-left: calc(1.5rem + 25px + 0.5rem);
    font-size: ${theme.fontSize.BODY_XL}px;
    border: 2px solid ${theme.colors.PRIMARY_BASE};
    border-radius: 0;
    width: 100%;
    outline: none;
    user-select: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    svg {
        z-index: 1;
        position: absolute;
        inset-inline-start: 1.5rem;
        inset-block-start: 50%;
        transform: translateY(-50%);
        fill: ${theme.colors.PRIMARY_BASE};
        width: 25px;
    }

    &:active, &:focus {
        color: ${theme.colors.SECONDARY_BASE};
        border-color: ${theme.colors.SECONDARY_BASE};

        svg {
            fill: ${theme.colors.SECONDARY_BASE};
        }
    }

    span {
        min-height: 1lh;

        &::before {
            float: left;
            content: '\\200B';
        }
    }

    ${isDisabled && `
        opacity: 0.4;
        // background-color: ${theme.colors.PRIMARY_LIGHT};
        pointer-events: none;
    `}
  `
);

export const List = styled('ul').withConfig({
  shouldForwardProp: (prop) => ![
      'theme',
      'isActive'
  ].includes(prop)
})<{
  theme: DefaultTheme;
  isActive?: boolean;
}>(
  ({
    theme,
    isActive
  }) => css`
    margin: 0;
    padding: 0;
    list-style-type: none;

    display: none;
    position: absolute;
    z-index: 10;
    max-height: 50vh;
    width: 100%;
    background-color: ${theme.colors.PRIMARY_BASE};
    // background: rgb(0 44 92 / 75%);
    // backdrop-filter: blur(10px) invert(0.1);
    overflow-y: auto;

    ${isActive && `display: block;`}
  `
);

export const Option = styled('li').withConfig({
  shouldForwardProp: (prop) => ![
      'theme',
      'isDisabled'
  ].includes(prop)
})<{
  theme: DefaultTheme;
  isDisabled?: boolean;
}>(
  ({
    isDisabled,
    theme
  }) => css`
    position: relative;
    padding: 1rem 1.5rem;

    padding-left: calc(1.5rem + 25px + 0.5rem);
    font-size: ${theme.fontSize.BODY_LG}px;
    color: #fff;
    outline: none;
    user-select: none;

    &:active, &:focus {
        // box-shadow: inset 0 0 0 2px ${theme.colors.SECONDARY_BASE};
        background-color: ${theme.colors.SECONDARY_BASE};
    }

    &:hover {
        // background-color: rgba(0,0,0,.1);
        background-color: #03a9f4;
        cursor: pointer;
    }

    svg {
        z-index: 1;
        position: absolute;
        inset-inline-start: 1.5rem;
        inset-block-start: 50%;
        transform: translateY(-50%);
        fill: #fff;
        width: 25px;
    }

    ${isDisabled && `
        opacity: 0.4;
        background-color: rgba(255,255,255,.4);
        pointer-events: none;
    `}
  `
);
