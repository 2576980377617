import { css, styled } from "styled-components";

import { NavLink } from 'react-router-dom';
import { DefaultTheme } from 'styled-components';

export const Header = styled('div').withConfig({
  shouldForwardProp: (prop) => ![
      'theme'
  ].includes(prop)
})<{
  theme: DefaultTheme
}>(
  ({ theme }) => css`
    color: #fff;
    background-color: ${theme.colors.PRIMARY_BASE};
    padding-top: env(safe-area-inset-top);

    & > div {
        display: flex;
        height: ${theme.header.height};
    }
  `
);

export const Title = styled('span')(
  () => css`
    flex-shrink: 0;
    font-size: 2rem;
    display: inline-flex;
    place-items: center;

    a {
        text-decoration: none;
        color: inherit;

        &::after {
            display: none;
        }

        &:hover {
            text-decoration: none;
        }
    }
  `
);

export const List = styled('ul').withConfig({
  shouldForwardProp: (prop) => ![
      'isActive'
  ].includes(prop)
})<{
    isActive?: boolean
}>(
  ({
    isActive
  }) => css`
    margin: 0;
    padding: 0;
    list-style-type: none;

    flex-basis: 100%;
    flex-grow: 1;

    display: inline-flex;
    margin: 0;
    flex-direction: row;
    align-items: center;
    grid-gap: 2rem;
    justify-content: flex-end;

    @media (max-width: 949px) {
        position: fixed;
        z-index: 150;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        flex-direction: column;
        justify-content: space-evenly;
        background: rgb(0 44 92 / 75%);
        backdrop-filter: blur(10px) invert(0.1);
    }

    ${isActive && `
        margin-inline-start: env(safe-area-inset-left);
        margin-inline-end: env(safe-area-inset-right);
        padding-block-start: env(safe-area-inset-top);
        padding-block-end: env(safe-area-inset-bottom);
    `}
    ${!isActive && `
        @media (max-width: 949px) {
            display: none;
        }
    `}

    li a {
        position: relative;
        text-decoration: none;
        color: inherit;
        font-size: 1.5rem;
    }
  `
);

export const Item = styled(NavLink).withConfig({
  shouldForwardProp: (prop) => ![
      'theme'
  ].includes(prop)
})<{
  theme: DefaultTheme,
}>(
  ({ theme }) => css`
    &::after {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
        content: '';
        height: 2px;
        background: transparent;
    }

    &.active {
        &::after {
            background: ${theme.colors.SECONDARY_BASE};
        }
    }
  `
);

export const Toggle = styled('div').withConfig({
  shouldForwardProp: (prop) => ![
      'theme',
      'isActive'
  ].includes(prop)
})<{
    theme: DefaultTheme,
    isActive?: boolean
}>(
  ({
    theme,
    isActive
  }) => css`
    position: absolute;
    z-index: 200;
    top: 0;
    right: 0;
    height: ${theme.header.height};
    width: ${theme.header.height};
    cursor: pointer;
    margin-inline-start: auto;

    @media (min-width: 950px) {
        display: none;
    }

    &::before,
    &::after {
        transition: all ease-in-out .2s;
    }

    &::before {
        position: absolute;
        display: block;
        content: '';
        background: #fff;
        height: 2px;
        width: 30px;
        left: 50%;
        top: 50%;
        transform: rotate(0deg) translate(-50%, -1rem);
        transform-origin: 0 0;
        box-shadow: 0px 1rem #fff;
    }
    &::after {
        position: absolute;
        display: block;
        content: '';
        background: #fff;
        height: 2px;
        width: 30px;
        left: 50%;
        top: 50%;
        transform: rotate(0deg) translate(-50%, 1rem);
        transform-origin: 0 0;
    }

    ${isActive && `
        position: fixed;
        right: env(safe-area-inset-right);

        &::before {
            transform: rotate(-45deg) translate(-50%, -50%);
            box-shadow: 0px 0px #fff;
        }
        &::after {
            transform: rotate(45deg) translate(-50%, -50%);
        }
    `}
  `
);
