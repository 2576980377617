import { DefaultTheme, css, styled } from "styled-components";

export const Input = styled('input').withConfig({
  shouldForwardProp: (prop) => ![
      'theme'
  ].includes(prop)
})<{
  theme: DefaultTheme
}>(
  ({ theme }) => css`
    padding: 1rem 1.5rem;
    font-size: 1.2rem;
    border: 2px solid ${theme.colors.PRIMARY_BASE};
    border-radius: 0;
    width: 100%;
    outline: none;
    font-family: inherit;
    resize: none;

    &:active, &:focus {
      border-color: ${theme.colors.SECONDARY_BASE};
    }

    &:disabled {
      background-color: ${theme.colors.PRIMARY_LIGHT};
      opacity: 0.4;
    }
  `
);
