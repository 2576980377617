import { css, styled } from "styled-components";

export const ToastPage = styled('div')(
  () => css`
    background-color: #fff;
    min-height: calc(100% - 200px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    
    :first-child {
        margin-block-start: 0;
    }
    :last-child {
        margin-block-end: 0;
    }
  `
);
