import Loader from '@Components/Loader';

import * as S from './FallbackLoader.theme';

function FallbackLoader() {
    return (
        <S.FallbackLoader>
            <Loader isLoading />
        </S.FallbackLoader>
    );
}

export default FallbackLoader;
