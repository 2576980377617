import { Modal as ModalType } from '@Types/Modal/Modal.types';
import Modal from '@Components/Modal';
import Loader from '@Components/Loader';

import * as S from './LoadingModal.theme';

const LoadingModal: React.FC<ModalType> = (modal: ModalType) => {
    return (
        <Modal { ...modal }>
            <S.LoadingModal>
                <Loader isLoading />
            </S.LoadingModal>
        </Modal>
    );
};

export default LoadingModal;
