import ContentWrapper from '@Components/ContentWrapper';

import * as S from './StyleGuideTheme.theme';
import { useDocumentTitle } from '@Hooks/useDocumentTitle';
import { useTheme } from 'styled-components';

function StyleGuideTheme() {
    const theme = useTheme();
    useDocumentTitle('Style Guide - Theme');

    return (
        <S.StyleGuideTheme>
            <ContentWrapper>
                <S.Heading theme={theme}>Theme</S.Heading>
                <p>Below you can find all theme components that are available.</p>
            </ContentWrapper>
        </S.StyleGuideTheme>
    );
}

export default StyleGuideTheme;
