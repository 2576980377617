import { FormEvent, useState } from 'react';

import ContentWrapper from '@Components/ContentWrapper';
import InputText from '@Components/InputText';

import * as S from './StyleGuideInputText.theme';
import { useDocumentTitle } from '@Hooks/useDocumentTitle';
import { useTheme } from 'styled-components';

function StyleGuideInputText() {
    const theme = useTheme();
    useDocumentTitle('Style Guide - InputText');

    const [value, setValue] = useState<string>('onChange');
    const handleChange = (event: FormEvent<HTMLInputElement>) => {
        setValue(event.currentTarget.value);
    };

    return (
        <S.StyleGuideInputText>
            <ContentWrapper>
                <S.Heading theme={theme}>InputText</S.Heading>
                <p>Below you can find all different types of input text and options available.</p>
                <InputText />
                <br/><br/>
                <InputText placeholder="placeholder" />
                <br/><br/>
                <InputText value="value" />
                <br/><br/>
                <InputText onChange={handleChange} value={value} />
                <br/><br/>
                <InputText value="disabled" disabled />
                <br/><br/>
                <InputText value="readOnly" readOnly />
                <br/><br/>
                <div style={{width: '100%', maxWidth: '600px'}}>
                    <p>The below button is wrapped in div with a max-width of 600px.</p>
                    <InputText onChange={handleChange} value={value} />
                </div>
                <br/>
                <div style={{width: '100%', maxWidth: '60%'}}>
                    <p>The below button is wrapped in div with a max-width of 60%.</p>
                    <InputText onChange={handleChange} value={value} />
                </div>
            </ContentWrapper>
        </S.StyleGuideInputText>
    );
}

export default StyleGuideInputText;
