// To-do: Make this file NOT importable unless specified (to force usage of useTheme)
export const theme = {
    header: {
        height: '100px',
    },
    footer: {
        height: '100px',
    },
    fontSize: {
        HEADING_H1: 32,
        HEADING_H2: 24,
        HEADING_H3: 19,
        BODY_XL: 20,
        BODY_LG: 18,
        BODY_MD: 16,
        BODY_SM: 14,
        BODY_XS: 12,
    },
    spacing: {
        XL: 35,
        LG: 28,
        MD: 21,
        SM: 14,
        XS: 7,
    },
    colors: {
        PRIMARY_BASE: '#002c5c',
        PRIMARY_DARK: '#FF0000', // Fix
        PRIMARY_LIGHT: '#e8ecef',
        SECONDARY_BASE: '#03a9f4',
        SECONDARY_DARK: '#FF0000', // Fix
        SECONDARY_LIGHT: '#FF0000', // Fix
        TERTIARY_BASE: '#f7f7f7',
        TERTIARY_DARK: '#FF0000', // Fix
        TERTIARY_LIGHT: '#FF0000', // Fix
        SEPARATOR_COLOR: '#E8E8E8',
    }
};
