/**
 * Returns a formatted month.
 * @param { Date } date
 * @returns { String } Example 'May'
 */
export const getFormattedMonth = (date: Date) => {
    return date.toLocaleString('default', { month: 'short' });
};

/**
 * Calculates the difference of years, and months between two given dates.
 * @param { Date } startDate
 * @param { Date } endDate
 * @returns { Array } Array [years, months] of numbers.
 */
export const getDateYearsMonthsDiff = (startDate: Date, endDate: Date) => {
    const startYear = startDate.getFullYear();
    const startMonth = startDate.getMonth() + 1;
    const endYear = endDate.getFullYear();
    const endMonth = endDate.getMonth() + 1;

    const absYearDiff = endYear - startYear;
    const absMonthDiff = (endMonth - startMonth) + 1; // Add 1 to be inclusive (current month counts)

    const years = absMonthDiff < 0 ? absYearDiff - 1 : absYearDiff;
    const months = absMonthDiff < 0 ? 12 + absMonthDiff : absMonthDiff;

    return [years, months];
};

/**
 * Formats the difference of years, and months between two given dates.
 * @param { Date } startDate
 * @param { Date } endDate
 * @returns { String } Example '1 year, 2 months'
 */
export const getFormattedDateDiff = (startDate: Date, endDate: Date) => {
    const [years, months] = getDateYearsMonthsDiff(startDate, endDate);

    const time = [];

    years && time.push(`${years} ${years > 1 ? 'years' : 'year'}`);
    months && time.push(`${months} ${months > 1 ? 'months' : 'month'}`);

    return time.join(', ');
};
