/* eslint-disable react/prop-types */
import { useState } from 'react';

import Button from '@Components/Button';
import FormGroup from '@Components/FormGroup';
import Field from '@Components/Field';
import InputText from '@Components/InputText';
import Loader from '@Components/Loader';

import {
    Form as FinalForm,
    Field as FinalField
} from 'react-final-form';

import * as S from './LoginForm.theme';
import useAuth from '@Hooks/useAuth';

export const CONTACT_FORM_API_URL = 'contact.json';

const Errors = ({ submitFailed, meta }) => (
    <S.ErrorList>
        {submitFailed && meta.error && meta.touched && meta.error.map((error) => <S.ErrorItem key={ error }>{ error }</S.ErrorItem>) }
    </S.ErrorList>
);

function LoginForm() {
    // const { addToast } = useToast();
    const [isLoading] = useState(false);

    const { login } = useAuth();

    const onSuccess = async ({ email, password }) => {
        login(email, password);
    };

    const validateFields = (values) => {
        const errors = {
            email: [],
            password: []
        };

        !values.email && errors.email.push('This field is required');
        const isValidEmail = (value) => /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/.test(value);
        values.email && !isValidEmail(values.email) && errors.email.push('Please enter a valid email');

        !values.password && errors.password.push('This field is required');
        values.password && values.password.length > 30 && errors.password.push('Max. 30 characters');

        return errors;
    };

    return (
        <>
            <Loader isLoading={ isLoading } />
            <FinalForm
                onSubmit={onSuccess}
                validate={validateFields}
                initialValues={{
                    email: 'mojo@coyote.com',
                    password: 'yipyapyop123'
                }}
                render={({ handleSubmit, form, submitting, pristine, submitFailed }) => ( // values, valid, submitFailed
                    <form onSubmit={handleSubmit}>
                        <fieldset disabled={ isLoading || submitting } style={ { all: 'unset', width: '100%' } }>
                            <FormGroup columns={1}>
                                <FinalField
                                    name="email"
                                    type="text"
                                    placeholder="E-mail"
                                >
                                    { ({ input, meta, placeholder }) => (
                                        <Field>
                                            <label htmlFor="email">{ placeholder }</label>
                                            <InputText id="email" {...input} placeholder="your@email.com" />
                                            <Errors submitFailed={submitFailed} meta={meta} />
                                        </Field>
                                    ) }
                                </FinalField>
                                <FinalField
                                    name="password"
                                    type="text" // password
                                    placeholder="Password"
                                >
                                    { ({ input, meta, placeholder }) => {
                                        return (
                                            <Field>
                                                <label htmlFor="password">{ placeholder }</label>
                                                <InputText id="password" {...input} placeholder="Your password" />
                                                <Errors submitFailed={submitFailed} meta={meta} />
                                            </Field>
                                        );
                                    } }
                                </FinalField>
                                <Button type="submit" disabled={isLoading || submitting}>Login</Button>
                            </FormGroup>
                        </fieldset>
                        {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                    </form>
                )}
            />
        </>
    );
}

export default LoginForm;
