import { useTheme } from 'styled-components';
import * as S from './Loader.theme';

function Loader({
    isLoading
}: {
    isLoading: boolean
}) {
    if (!isLoading) {
        return null;
    }

    const theme = useTheme();

    return (
        <S.Loader theme={theme}>
            <S.Spinner/>
        </S.Loader>
    );
}

export default Loader;
