import { css, styled, DefaultTheme } from "styled-components";

export const ToastList = styled('div').withConfig({
  shouldForwardProp: (prop) => ![
      'theme'
  ].includes(prop)
})<{
  theme: DefaultTheme
}>(
  ({ theme }) => css`    
    position: fixed;
    inset-block-start: calc(${theme.header.height} + ${theme.spacing.MD}px);
    inset-inline: ${theme.spacing.MD}px;
    z-index: 300;
    display: grid;
    max-width: 600px;
  `
);
