type PostLogoutParameters = {
    refreshToken: string;
};

type PostLogoutResponseType = Response;

export const postLogout = (parameters: PostLogoutParameters) => {
    console.log('### postLogout parameters', parameters);

    return fetch(`http://localhost:3001/v1/auth/logout`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': `bearer ${getAuthToken()}`
            /// ...customHeaders
        },
        body: JSON.stringify(parameters)
    }) as Promise<PostLogoutResponseType>;
}
