import ContentWrapper from '@Components/ContentWrapper';
import IconLinkedIn from '@Components/IconLinkedIn';
import IconGithub from '@Components/IconGithub';
import IconStackOverflow from '@Components/IconStackOverflow';
import IconX from '@Components/IconX';

import * as S from './Footer.theme';
import { useTheme } from 'styled-components';

const Footer = () => {
    const theme = useTheme();
    const year = new Date().getFullYear();

    return (
        <S.Footer theme={theme}>
            <ContentWrapper>
                <S.ContentWrapper>
                    <S.Copyright>Copyright &copy; {year} Joost Meijer</S.Copyright>
                    <S.Socials theme={theme}>
                        <li>
                            <a href="https://www.linkedin.com/in/joost-meijer/" title="LinkedIn" target="_blank" rel="noreferrer">
                                <IconLinkedIn />
                            </a>
                        </li>
                        <li>
                            <a href="https://github.com/joostm020" title="GitHub" target="_blank" rel="noreferrer">
                                <IconGithub />
                            </a>
                        </li>
                        <li>
                            <a href="https://stackoverflow.com/users/9409639/joost-meijer" title="Stack Overflow" target="_blank" rel="noreferrer">
                                <IconStackOverflow />
                            </a>
                        </li>
                        <li>
                            <a href="https://x.com/joostm020" title="X" target="_blank" rel="noreferrer">
                                <IconX />
                            </a>
                        </li>
                    </S.Socials>
                </S.ContentWrapper>
            </ContentWrapper>
        </S.Footer>
    );
};

export default Footer;
