import { Modal } from '@Types/Modal/Modal.types';

export type OpenModalAction = {
    type: 'OPEN_MODAL',
    payload: Modal
};

export const openModal = (modal: Modal): OpenModalAction => ({
    type: 'OPEN_MODAL',
    payload: modal
});

export type CloseModalAction = {
    type: 'CLOSE_MODAL',
    payload: {
        id: string
    }
};

export const closeModal = (id: Modal['id']): CloseModalAction => ({
    type: 'CLOSE_MODAL',
    payload: {
        id
    }
});
