import ContentWrapper from '@Components/ContentWrapper';
import Button from '@Components/Button';
import ButtonGroup from '@Components/ButtonGroup';
import * as S from './StyleGuideModal.theme';
import { useDocumentTitle } from '@Hooks/useDocumentTitle';
import { useTheme } from 'styled-components';
import useModal from '@Hooks/useModal';
import useConfirmation from '@Hooks/useConfirmation';

function StyleGuideModal() {
    const theme = useTheme();
    useDocumentTitle('Style Guide - Modal');

    const { closeModal } = useModal();

    const openConfirmation = useConfirmation();

    const handleClickOpenModal = () => {
        openConfirmation({
            title: 'Confirm your booking',
            message: 'Would you like to confirm your booking?',
            onConfirm: () => {
                alert('useConfirmation.tsx Confirmed!');
            },
            onCancel: () => {
                alert('useConfirmation.tsx Canceled!');
            }
        });
    };
    
    const handleClickCloseModal = () => closeModal('test');

    return (
        <S.StyleGuideModal>
            <ContentWrapper>
                <S.Heading theme={theme}>Modal</S.Heading>
                <p>Below you can find all different types of modals and options available.</p>

                <ButtonGroup>
                    <Button onClick={handleClickOpenModal}>Open modal</Button>
                    <Button onClick={handleClickCloseModal}>Close modal</Button>
                </ButtonGroup>

                {/* <pre>
                    {JSON.stringify(state, null, 2)}
                </pre> */}
                <p>To-do:</p>
                <ul>
                    <li>[X] Hide dispatch in custom hook and only expose openModal</li>
                    <li>[X] Allow every modal to have a configured loader component (fallback to default)</li>
                    <li>[X] Refactor LazyLoader</li>
                    <li>[X] Convert Modal and LazyLoader to TypeScript</li>
                    <li>[X] Enforce loaded ModalComponent to implement props from Modal</li>
                    <li>[X] Refactor and improve typing of Modals</li>
                    <li>[X] Rename config to payload/data?</li>
                    <li>[X] Implement generics for different modals with different props.</li>
                    <li>[X] Remove timeout from LazyLoader</li>
                    <li>[X] Make a central modal registry that maps the openModal definitions to something simple with only the necessary props</li>
                    <li>[X] Allow modal config to have custom LazyLoader config</li>
                </ul>
                <p>To-do later:</p>
                <ul>
                    <li>[_] Study difference of React.FC, React.ElementType, JSX.Element</li>
                </ul>
                <p>Requirements:</p>
                <ul>
                    <li>[X] Modals should have a pre-defined height and width</li>
                    <li>[X] Modals instantly open and are part of the main chunk</li>
                    <li>[X] Modal content is lazy loaded and NOT part of the main chunk</li>
                </ul>
            </ContentWrapper>
        </S.StyleGuideModal>
    );
}

export default StyleGuideModal;
