import { css, styled } from "styled-components";

export const LoginForm = styled('div')(
  () => css`
    background-color: #fff;
    min-height: calc(100% - 200px - env(safe-area-inset-top) - env(safe-area-inset-bottom));

    :first-child {
        margin-block-start: 0;
    }
    :last-child {
        margin-block-end: 0;
    }
  `
);

export const ErrorList = styled('ul')(
  () => css`
    list-style-type: none;
    padding-inline: 0;
    margin-block-start: 0;
    min-height: 1lh;

    &::before {
        float: left;
        content: '\\200B';
    }
  `
);

export const ErrorItem = styled('li')(
  () => css`
    color: red;
  `
);

export const SubmittedConfirmation = styled('div')(
  () => css`
    height: 100%;
  `
);
