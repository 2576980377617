import { css, styled } from "styled-components";

export const FormGroup = styled('div').withConfig({
  shouldForwardProp: (prop) => ![
      'isHorizontal',
      'columns'
  ].includes(prop)
})<{
    isHorizontal?: boolean;
    columns?: number;
}>(
  ({
    isHorizontal,
    columns = 1
  }) => css`
    display: grid;
    grid-gap: 0.5rem;
    position: relative;
    grid-template-columns: repeat(${columns}, 1fr);

    ${isHorizontal && `
        grid-auto-flow: column;
    `}
  `
);
