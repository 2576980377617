import { useEffect } from "react";

/**
 * This hook enables keyboard navigation using the arrow keys up and down
 * over focusable elements with a tabindex, within the given ref.
 * @param {*} ref This ref should contain selectable elements with a tabindex.
 * @param {*} wrapperRef Optionally you can provide a wrapper ref to act as focus boundary.
 */
export const useArrowKeysTabindexNavigation = (
    ref: React.RefObject<HTMLElement>,
    wrapperRef?: React.RefObject<HTMLElement>
) => {
    useEffect(
        () => {
            const listener = (event: KeyboardEvent) => {
                if (!ref.current) {
                    return;
                }

                // Don't do anything, use native tab index behavior
                if (event.key === 'Tab') {
                    return;
                }

                const elements = ref.current.querySelectorAll('[tabindex]') as NodeListOf<HTMLElement>;
                const focusedIndex = Array.from(elements).indexOf(document.activeElement as HTMLElement);

                let updatedIndex = focusedIndex;

                if (event.key === 'ArrowUp') {
                    if (focusedIndex > 0) {
                        updatedIndex--;
                    } else {
                        updatedIndex = elements.length - 1;
                    }
                }
                else if (event.key === 'ArrowDown') {
                    if (focusedIndex < elements.length - 1) {
                        updatedIndex++;
                    } else {
                        updatedIndex = 0;
                    }
                }

                // Only focus if index changed
                if (focusedIndex !== updatedIndex) {
                    elements[updatedIndex].focus();

                    // Prevent page scroll only if focus has changed
                    event.preventDefault();
                }
            };

            if (wrapperRef && wrapperRef.current) {
                wrapperRef.current.addEventListener("keydown", listener);
            } else if (ref && ref.current) {
                ref.current.addEventListener("keydown", listener);
            }

            return () => {
                if (wrapperRef && wrapperRef.current) {
                    wrapperRef.current.removeEventListener("keydown", listener);
                } else if (ref && ref.current) {
                    ref.current.removeEventListener("keydown", listener);
                }
            };
        },
        [ref]
    );
}

export default useArrowKeysTabindexNavigation;
