import { ReactNode } from 'react';
import * as S from './ContentWrapper.theme';

interface PropsType {
    children?: ReactNode;
}

const ContentWrapper: React.FC<PropsType> = ({
    children
}: PropsType) => (
    <S.ContentWrapper>
        { children }
    </S.ContentWrapper>
);

export default ContentWrapper;
