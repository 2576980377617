import { css, styled } from "styled-components";

const getType = (type: string) => {
    switch(type) {
        case 'warning':
            return `background: rgba(255, 180, 120, 0.5);`;
        case 'error':
            return `background: rgba(255, 120, 120, 0.5);`;
        case 'success':
            return `background: rgba(120, 255, 120, 0.5);`;
        default:
            return ``;
    }
};

export const Toast = styled('div').withConfig({
  shouldForwardProp: (prop) => ![
      'type'
  ].includes(prop)
})<{
    type: string
}>(
  ({
    type
  }) => css`
    display: flex;
    grid-gap: 1rem;
    background: rgb(232 236 239 / 50%);
    backdrop-filter: blur(10px) invert(0.1);
    width: 100%;
    padding: 1rem;
    overflow: hidden;
    margin-block-end: 1rem;

    ${ getType(type) }
  `
);

export const Icon = styled('div')(
  () => css`
    display: grid;
    place-items: center;

    svg {
        width: 50px;
        height: 50px;
    }
  `
);

export const Content = styled('div')(
  () => css`
    flex-basis: 100%;

    p {
        margin: 0;
    }
  `
);

export const Dismiss = styled('div')(
  () => css`
    display: grid;
    place-items: center;

    svg {
        width: 25px;
        height: 25px;
    }
  `
);
