import Router from '@Components/Router';
import ToastProvider from '@Components/ToastProvider';

import { GlobalStyle } from './App.theme';
import { ThemeProvider } from 'styled-components';
import { theme } from '@Style/theme';
import ModalProvider from '@Components/ModalProvider';
import AuthProvider from '@Components/AuthProvider';

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle theme={theme} />
            <ModalProvider>
                <ToastProvider>
                    <AuthProvider>
                        <Router />
                    </AuthProvider>
                </ToastProvider>
            </ModalProvider>
        </ThemeProvider>
    );
};

export default App;
