import ContentWrapper from '@Components/ContentWrapper';
import Image from '@Components/Image';
import { useDocumentTitle } from '@Hooks/useDocumentTitle';

import photo from '@Media/photo/portrait_square_min.png';

import * as S from './About.theme';
import { useTheme } from 'styled-components';

function About() {
    const theme = useTheme();
    useDocumentTitle('About me');

    return (
        <S.About>
            <ContentWrapper>
                <S.Heading theme={theme}>About me</S.Heading>
                <S.Photo>
                    <Image src={photo} height="476" width="370" alt="Joost Meijer" />
                </S.Photo>
                <p>Software Engineer with over 7 years of professional experience, including over 5 years of experience in front-end development, specialising in building high-quality applications with JavaScript/TypeScript.</p>
                <p>I&apos;m passionate about front-end development and anything related to modern web technologies, and I like coding, learning and sharing knowledge, problem-solving, collaborating, and making a positive impact.</p>
                <p>Regularly used: JavaScript, TypeScript, React.js, Jest, Node.js, NPM, Yarn, Webpack, Redux, GraphQL, ESLint, HTML, CSS (SCSS), PHP (Zend), Magento 2, MySQL, Docker, Python, TDD, DRY, SOLID, GitHub, Git, Agile/Scrum.</p>
            </ContentWrapper>
        </S.About>
    );
}

export default About;
