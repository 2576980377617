import { ReactNode } from 'react';
import * as S from './FormGroup.theme';

interface PropsType {
    children?: ReactNode;
    isHorizontal?: boolean;
    columns?: number;
}

const FormGroup: React.FC<PropsType> = ({
  children,
  isHorizontal,
  columns
}: PropsType) => {
    return (
        <S.FormGroup isHorizontal={isHorizontal} columns={columns}>
            { children }
        </S.FormGroup>
    );
}

export default FormGroup;
