import { DefaultTheme, css, styled } from "styled-components";

export const Loader = styled('div')(
  () => css`
    @keyframes loader-spinner {
      0% {
          transform: rotate(0deg);
      }

      100% {
          transform: rotate(360deg);
      }
    }

    background-color: rgba(255, 255, 255, .75);
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    inset-inline-end: 0;
    inset-block-end: 0;
    z-index: 75;
    margin: auto;
    animation: appear 1ms 200ms forwards;
    display: grid;
    place-items: center;
  `
);

export const Spinner = styled('div').withConfig({
  shouldForwardProp: (prop) => ![
      'theme'
  ].includes(prop)
})<{
  theme: DefaultTheme
}>(
  ({ theme }) => css`
    display: inline-block;
    width: 50px;
    height: 50px;

    &::after {
        content: " ";
        display: block;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 6px solid ${theme.colors.PRIMARY_BASE};
        border-color: ${theme.colors.PRIMARY_BASE} transparent ${theme.colors.PRIMARY_BASE} transparent;
        animation: loader-spinner 1.2s linear infinite;
    }
  `
);
