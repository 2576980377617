import { DefaultTheme, css, styled } from "styled-components";

export const Footer = styled('div').withConfig({
  shouldForwardProp: (prop) => ![
      'theme'
  ].includes(prop)
})<{
  theme: DefaultTheme
}>(
  ({ theme }) => css`
    color: ${theme.colors.PRIMARY_BASE};
    background-color: ${theme.colors.PRIMARY_LIGHT};
    padding-bottom: env(safe-area-inset-bottom);
    height: ${theme.footer.height};
  `
);

export const ContentWrapper = styled('div')(
  () => css`
    display: flex;
    align-items: center;
    display: flex;
  `
);

export const Copyright = styled('p')(
  () => css`
    flex-grow: 1;
  `
);

export const Socials = styled('ul').withConfig({
  shouldForwardProp: (prop) => ![
      'theme'
  ].includes(prop)
})<{
  theme: DefaultTheme
}>(
  ({ theme }) => css`
    flex-shrink: 0;
    display: flex;
    grid-gap: 0.5rem;
    align-items: baseline;

    margin: 0;
    padding: 0;
    list-style-type: none;

    svg {
        fill: ${theme.colors.PRIMARY_BASE};
        width: 1.5rem;
    }

    a {
        outline: none;

        &:active, &:focus, &:hover {
            & svg {
                fill: ${theme.colors.SECONDARY_BASE};
            }
        }
    }
  `
);
