import { useState } from 'react';

import ContentWrapper from '@Components/ContentWrapper';
import Button from '@Components/Button';
import ButtonGroup from '@Components/ButtonGroup';

import { useToast } from '@Hooks/useToast';

import * as ToastTypes from '@Types/Toast/Toast.types';
import * as S from './ToastPage.theme';

import FormGroup from '@Components/FormGroup';
import { useDocumentTitle } from '@Hooks/useDocumentTitle';

function ToastPage() {
    useDocumentTitle('Toast Page');

    const { addToast, clearToasts } = useToast();

    const [title, setTitle] = useState<ToastTypes.ToastNotificationTitle>('Title here');
    const [message, setMessage] = useState<ToastTypes.ToastNotificationMessage>('Hello world!');
    const [type, setType] = useState<ToastTypes.ToastNotificationType>('default');
    const [isDismissable, setIsDismissable] = useState<ToastTypes.ToastNotificationIsDismissable>(true);
    const [autoDismiss, setIsAutoDismiss] = useState<ToastTypes.ToastNotificationAutoDismiss>(true);
    const [autoDismissTimeout, setAutoDismissTimeout] = useState<ToastTypes.ToastNotificationAutoDismissTimeout>(1000);

    const handleClick = () => {
        addToast(title, message, type, {
            isDismissable,
            autoDismiss,
            autoDismissTimeout
        });
    };

    return (
        <S.ToastPage>
            <ContentWrapper>
                <h1>ToastPage test page</h1>

                <p>To-do:</p>
                <ul>
                    <li>[X] Add unique key to toast item as identifier</li>
                    <li>[X] Remove toast after x seconds (default)</li>
                    <li>[X] Remove toast after configured seconds (option)</li>
                    <li>[X] Add transitions in and out</li>
                    <li>[X] Add title</li>
                    <li>[X] Add dismiss button</li>
                    <li>[X] Add TypeScript types</li>
                    <li>[_] Support for multiple notifications at once</li>
                </ul>

                <FormGroup>
                <label>Type:
                    <select onChange={(event) => setType(event.target.value as ToastTypes.ToastNotificationType)}>
                        <option value="default">Default</option>
                        <option value="success">Success</option>
                        <option value="warning">Warning</option>
                        <option value="error">Error</option>
                    </select>
                </label>
                <label>Title:
                    <input type="text" value={title} onChange={(event) => setTitle(event.target.value)} />
                </label>
                <label>Message:
                    <input type="text" value={message} onChange={(event) => setMessage(event.target.value)} />
                </label>
                <label>Is dismissable:
                    <input type="checkbox" checked={isDismissable} onChange={(event) => setIsDismissable(event.target.checked)} />
                </label>
                <label>Auto dismiss:
                    <input type="checkbox" checked={autoDismiss} onChange={(event) => setIsAutoDismiss(event.target.checked)} />
                </label>
                <label>Auto dismiss timeout:
                    <input type="number" value={autoDismissTimeout} onChange={(event) => setAutoDismissTimeout(+event.target.value)} />
                </label>

                <ButtonGroup>
                    <Button onClick={ handleClick }>Add notification</Button>
                    <Button onClick={ clearToasts } isHollow>Clear</Button>
                </ButtonGroup>
                </FormGroup>
            </ContentWrapper>
        </S.ToastPage>
    );
}

export default ToastPage;
