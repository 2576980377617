import React, { PropsWithChildren, useState, useCallback } from 'react';
import ToastList from '@Components/ToastList';

import { generateRandomHash } from '@Util/Hash';

export const DEFAULT_TYPE = 'default';
export const DEFAULT_IS_DISMISSABLE = true;
export const DEFAULT_AUTO_DISMISS = true;
export const DEFAULT_AUTO_DISMISS_TIMEOUT = 3000;

import * as ToastTypes from '@Types/Toast/Toast.types';

export interface ToastContext {
    toasts: ToastTypes.ToastNotifications
    addToast: IAddToast,
    removeToast: IRemoveToast,
    clearToasts: IClearToasts
}

export const ToastContext = React.createContext<ToastContext>({} as ToastContext);

export interface IAddToast {
    (
        title: ToastTypes.ToastNotificationTitle,
        message: ToastTypes.ToastNotificationMessage,
        type?: ToastTypes.ToastNotificationType,
        options?: {
            isDismissable?: ToastTypes.ToastNotificationIsDismissable,
            autoDismiss?: ToastTypes.ToastNotificationAutoDismiss,
            autoDismissTimeout?: ToastTypes.ToastNotificationAutoDismissTimeout
        }
    ): void
}

export interface IRemoveToast {
    (id: ToastTypes.ToastNotificationId): void
}

export interface IClearToasts {
    (): void
}

export default function ToastProvider({ children }: PropsWithChildren) {
    const [toasts, setToasts] = useState<ToastTypes.ToastNotifications>([]);

    const addToast = useCallback<IAddToast>(
        (
            title,
            message,
            type = DEFAULT_TYPE,
            options = {}
        ) => {
            const {
                isDismissable = DEFAULT_IS_DISMISSABLE,
                autoDismiss = DEFAULT_AUTO_DISMISS,
                autoDismissTimeout = DEFAULT_AUTO_DISMISS_TIMEOUT
            } = options;

            setToasts((toasts) => [
                ...toasts,
                {
                    id: generateRandomHash(),
                    type,
                    title,
                    message,
                    isDismissable,
                    autoDismiss,
                    autoDismissTimeout
                }
            ]);
        },
        [setToasts]
    );

    const removeToast = useCallback<IRemoveToast>(
        (id) => {
            setToasts((toast) => toast.filter(t => t.id !== id));
        },
        [setToasts]
    );

    const clearToasts = useCallback<IClearToasts>(
        () => {
            setToasts([]);
        },
        [setToasts]
    );

    return (
        <ToastContext.Provider value={ {
            toasts,
            addToast,
            removeToast,
            clearToasts
        } }>
            {children}
            <ToastList />
        </ToastContext.Provider>
    );
}
