import Toast from '@Components/Toast';

import { useTransition, animated } from '@react-spring/web';

import { useToast } from '@Hooks/useToast';

import * as S from './ToastList.theme';
import { useTheme } from 'styled-components';

const ToastList = () => {
    const theme = useTheme();
    const { toasts } = useToast();

    const transitions = useTransition(toasts, {
        from: { transform: "translateX(-100%) rotateX(0deg)", maxHeight: '100vh' },
        enter: { transform: "translateX(0%) rotateX(0deg)", maxHeight: '100vh' },
        leave: { transform: "translateX(0%) rotateX(90deg)", maxHeight: '0vh' }
    });

    return (
        <S.ToastList theme={theme} className="ToastList">
            { transitions((style, item) => (
                <animated.div style={{
                    overflow: 'hidden',
                    ...style
                }}>
                    <Toast {...item} />
                </animated.div>
            )) }
        </S.ToastList>
    );
}

export default ToastList;
