import { DefaultTheme, css, styled } from "styled-components";
import { placeholderAnimation } from "@Style/animations/placeholder";

export const Image = styled('div').withConfig({
    shouldForwardProp: (prop) => ![
        'theme',
        'isError',
        'isLoading'
    ].includes(prop)
})<{
    theme: DefaultTheme;
    isError: boolean;
    isLoading: boolean;
}>(
  ({
    theme,
    isError,
    isLoading,
  }) => css`
    position: relative;
    height: 100%;

    img {
        display: block;
        width: 100%;
        height: auto;
        vertical-align: middle;
    }

    ${isLoading && `
        position: relative;

        ${placeholderAnimation}

        &::before {
            display: block;
            content: " ";
            position: absolute;
            inset: 0;
            z-index: 10;
            width: 100%;
            height: 100%;
            background: linear-gradient(90deg, transparent, gray, transparent);
            background-repeat: no-repeat;
            background-size: 10rem 100%;
            mix-blend-mode: color-burn;
            animation: placeholder-animation 1.5s ease-in-out infinite;

            @keyframes placeholder-animation {
                0% {
                    background-position-x: calc(0% - 10rem);
                }
                100% {
                    background-position-x: calc(100% + 10rem);
                }
            }
        }
        
        position: relative;
        opacity: 1;
        min-height: 1lh;

        &::before {
            content: '\\200B';
        }

        &::after {
            z-index: 1;
            position: absolute;
            inset: 0;
            display: block;
            content: '';
            border: 1px solid #fff;
            background-color: ${theme.colors.PRIMARY_LIGHT};
        }

        img {
            opacity: 0;
        }
    `}
    
    ${isError && `
        height: 100%;
        background-color: ${theme.colors.PRIMARY_LIGHT};

        img {
            opacity: 0;
        }

        svg {
            width: 100%;
            max-width: 50px;
        }
    `}
  `
);

export const Error = styled('div')(
  () => css`
    position: absolute;
    inset: 0;
    display: grid;
    place-items: center;
  `
);
